import React from "react";
import { Avatar, CircularProgress, Container, Box, CssBaseline } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import StaffLogin from "./StaffLogin";
import Copyright from "./Copyright";

export default function LoginContainer() {
  const { loginLoading } = useAuth();
  return (
    <Container component="main" maxWidth="xs" sx={{ height: "100vh" }}>
      <CssBaseline />
      {loginLoading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "8em",
          }}
        >
          <Avatar sx={{ width: 200, height: 200 }} src="TGPicon.png" />
          <StaffLogin />
          <Box sx={{ mt: 5 }}>
            <Copyright />
          </Box>
        </Box>
      )}
    </Container>
  );
}
