import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { itemsObjAtom, automationsResetAtom } from "../../recoil/atoms";
import { Paper, Typography, Button } from "@mui/material";
import useDeactivateDoc from "../../hooks/useDeactivateDoc";

export default function Automation({ automation }) {
  const { deactivate } = useDeactivateDoc();
  const setAutomationsReset = useSetRecoilState(automationsResetAtom);
  const itemsObj = useRecoilValue(itemsObjAtom);

  const handleDelete = () => {
    deactivate({ col: "automations", id: automation.id });
    setAutomationsReset((pV) => !pV);
  };

  return (
    <>
      {itemsObj && (
        <Paper sx={{ padding: 2, mt: 2, mb: 2, display: "flex" }}>
          <Typography variant="h5" sx={{ flexBasis: "80%" }}>
            If the description says <b>{automation.text}</b> then code it as{" "}
            <b>{itemsObj[automation.itemId].label}</b>
          </Typography>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete Automation
          </Button>
        </Paper>
      )}
    </>
  );
}
