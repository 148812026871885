import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./PrivateRoute";
import CustomRoute from "./CustomRoute";
import Settings from "./pages/Settings";
import Staff from "./pages/Staff";
import Summary from "./pages/Summary";
import Upload from "./pages/Upload";
import CodeTransactions from "./pages/CodeTransactions";
import Automate from "./pages/Automate";
import ExpenseTracking from "./pages/ExpenseTracking";
import { SITE_PERMISSIONS_OBJ } from "./libraries/objects";

export const routes = (
  <Routes>
    <Route exact path="/" element={<Login />} />
    <Route
      exact
      path="/settings"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.edit_settings}>
          <Settings />
        </CustomRoute>
      }
    />
    <Route
      exact
      path="/staff"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.edit_staff}>
          <Staff />
        </CustomRoute>
      }
    />
    <Route
      exact
      path="/upload"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.upload_transactions}>
          <Upload />
        </CustomRoute>
      }
    />
    <Route
      exact
      path="/summary"
      element={
        <PrivateRoute>
          <Summary />
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="/automate"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.manage_automations}>
          <Automate />
        </CustomRoute>
      }
    />
    <Route
      exact
      path="/expense-tracking"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.see_expense_tracking}>
          <ExpenseTracking />
        </CustomRoute>
      }
    />
    <Route
      exact
      path="/code-transactions"
      element={
        <CustomRoute permission={SITE_PERMISSIONS_OBJ.code_transactions}>
          <CodeTransactions />
        </CustomRoute>
      }
    />
  </Routes>
);
