import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import useDeactivateDoc from "../../hooks/useDeactivateDoc";
import DeleteConfirmation from "./DeleteConfirmation";

export default function FieldDeleteIcon({ selectedField }) {
  const { deactivate } = useDeactivateDoc();
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    deactivate({ col: selectedField.collection, id: selectedField.id });
    setOpen(false);
  };

  return (
    <>
      <Fab
        color="error"
        sx={{
          width: 25,
          lineHeight: 25,
          height: 25,
          minHeight: 25,
          marginLeft: 3,
          color: "#fff",
        }}
        size="small"
        aria-label="edit"
        onClick={handleConfirm}
      >
        <CloseIcon sx={{ fontSize: 25 * 0.72 }} />
      </Fab>
      <DeleteConfirmation open={open} setOpen={setOpen} handleDelete={handleDelete} />
    </>
  );
}
