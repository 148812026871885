import React from "react";
import { useRecoilValue } from "recoil";
import { groupedItemsAtom } from "../../recoil/atoms";
import { Typography, Box, Button, Divider } from "@mui/material";
import ItemDisplay from "./ItemDisplay";
import FieldEditIcon from "./FieldEditIcon";
import FieldDeleteIcon from "./FieldDeleteIcon";

export default function SectionDisplay({
  section,
  selectedField,
  setSelectedField,
  setCreateOpen,
  setCreateObj,
  setEditOpen,
}) {
  const groupedItems = useRecoilValue(groupedItemsAtom);

  const handleOpen = () => {
    setCreateObj({ parentId: section.id, collection: "items" });
    setCreateOpen(true);
  };
  const handleEnter = (value) => {
    setSelectedField({ ...value, collection: "sections" });
  };

  const handleExit = () => {
    setSelectedField(null);
  };
  return (
    <>
      <Box sx={{ mt: 2, ml: 12, display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", alignItems: "baseline" }}
          onMouseEnter={() => handleEnter(section)}
          onMouseLeave={handleExit}
        >
          <Typography variant="h4">{section.label}</Typography>
          {selectedField && selectedField.id === section.id && (
            <>
              <FieldEditIcon setEditOpen={setEditOpen} /> <FieldDeleteIcon selectedField={selectedField} />
            </>
          )}
        </Box>
        <Button variant="contained" onClick={handleOpen}>
          Create New Item
        </Button>
      </Box>
      <Divider sx={{ mt: 1, ml: 12 }} />
      {groupedItems &&
        groupedItems[section.id]?.map((i) => (
          <ItemDisplay
            key={i.id}
            item={i}
            setCreateObj={setCreateObj}
            setCreateOpen={setCreateOpen}
            setSelectedField={setSelectedField}
            selectedField={selectedField}
            setEditOpen={setEditOpen}
          />
        ))}
    </>
  );
}
