import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from "@mui/material";
import { useSetRecoilState } from "recoil";
import { categoriesResetAtom, sectionsResetAtom, itemsResetAtom } from "../../recoil/atoms";
import useAddDoc from "../../hooks/useAddDoc";

const blankForm = {
  label: "",
  order: "",
  amount: "",
};

const CreateDialog = ({ createObj, open, setOpen }) => {
  const [form, setForm] = useState({ ...blankForm });
  const [title, setTitle] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const setCategoriesReset = useSetRecoilState(categoriesResetAtom);
  const setSectionsReset = useSetRecoilState(sectionsResetAtom);
  const setItemsReset = useSetRecoilState(itemsResetAtom);

  useEffect(() => {
    if (!createObj) {
      return;
    }
    setTitle(
      createObj.collection === "categories"
        ? "Category"
        : createObj.collection === "sections"
        ? "Section"
        : "Item"
    );
  }, [createObj]);

  const handleChange = (e) => {
    setForm((prevValue) => {
      return { ...prevValue, [e.target.name]: e.target.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const data = {
      ...form,
      parentId: createObj.parentId,
      order: +form.order,
      amount: +form.amount,
    };
    if (createObj.collection === "items") {
      data.fiscalYear = "22-23";
    }
    await addDoc({ col: createObj.collection, data: data });
    setForm(blankForm);
    setCategoriesReset((pV) => !pV);
    setSectionsReset((pV) => !pV);
    setItemsReset((pV) => !pV);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 44 }}>Create {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{title} Label:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="label"
          type="text"
          fullWidth
          value={form.label}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Order</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="order"
          type="number"
          fullWidth
          value={form.order}
          variant="outlined"
          onChange={handleChange}
        />
        {createObj && createObj.collection === "items" && (
          <>
            <DialogContentText>Budgeted Amount</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="amount"
              type="number"
              fullWidth
              value={form.amount}
              variant="outlined"
              onChange={handleChange}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CreateDialog);
