import { TextField, Box, InputLabel, Select, MenuItem } from "@mui/material";
import { itemsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function TransactionDetailsForm({ form, setForm }) {
  const items = useRecoilValue(itemsAtom);

  const handleChange = (event) => {
    setForm((pV) => ({ ...pV, [event.target.name]: Number(event.target.value) }));
  };

  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        label="Label"
        value={form.label}
        fullWidth
        sx={{ mb: 3 }}
        onChange={handleChange}
        name="label"
      />
      <TextField
        label="Amount"
        value={Math.abs(form.amount)}
        type="number"
        fullWidth
        sx={{ mb: 3 }}
        onChange={handleChange}
        name="amount"
      />
      <InputLabel id="coding-label">Budget Item</InputLabel>
      <Select
        labelId="coding-label"
        value={form.itemId}
        label="Budget Item"
        onChange={handleChange}
        fullWidth
        name="itemId"
      >
        {items &&
          items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
}
