import React from "react";
import { Typography, Divider, Grid } from "@mui/material";

export default function ExpensesHeader({dimensions}) {
  return (
    <>
      <Grid container spacing={1} columns={dimensions.total} sx={{mb:2}}>
        <Grid item sm={dimensions.first}>
          <Typography sx={{ pl: 4 }} variant="h4">
            Item
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second}>
          <Typography align="right" variant="h4">
            Budgeted
          </Typography>
        </Grid>
        <Grid item sm={dimensions.third}>
        <Typography align="right" variant="h4">
            Spent
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fourth}>
        <Typography align="right" variant="h4">
            Left
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fifth}>
        <Typography align="right" variant="h4">
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
