import React, { useContext, useState, useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, db, provider } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserData, setCurrentUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then(async (result) => {
      setLoginLoading(true);
      const user = result.user;
      await setStaffData(user.email);
    });
  };

  const setStaffData = async (email) => {
    const staffData = await getStaffData(email);
    setCurrentUserData(staffData);
  };

  const getStaffData = async (email) => {
    const tempArray = [];
    const staffRef = collection(db, "staff");
    const q = query(staffRef, where("email", "==", email));
    const snapshot = await getDocs(q);
    if (snapshot.size === 0) {
      console.log("Not Found");
      //TODO: Handle No User w/ Email in Staff
      logout();
      setLoginLoading(false);
      return;
    }
    if (snapshot.size > 1) {
      console.log("Duplicate found");
      //TODO: Handle duplicate email in staff db
      logout();
      setLoginLoading(false);
      return;
    }
    snapshot.forEach((doc) => {
      tempArray.push({ id: doc.id, ...doc.data() });
    });
    return tempArray[0];
  };

  function logout() {
    setCurrentUserData(null);
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser({ ...user });
        const userData = await getStaffData(user.email);
        setCurrentUserData(userData);
      } else {
        logout();
      }
      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUserData) {
      setLoginLoading(false);
    }
  }, [currentUserData]);

  const value = {
    currentUser,
    currentUserData,
    loginLoading,
    signInWithGoogle,
    setCurrentUserData,
    logout,
    setLoginError,
    loginError,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
