import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";

export default function FieldEditIcon({ setEditOpen }) {

  const handleEditFieldOpen = () => {
    setEditOpen(true);
  };

  return (
    <Fab
      color="secondary"
      sx={{
        width: 25,
        lineHeight: 25,
        height: 25,
        minHeight: 25,
        marginLeft: 3,
        color: "#fff",
      }}
      size="small"
      aria-label="edit"
      onClick={handleEditFieldOpen}
    >
      <EditIcon sx={{ fontSize: 25 * 0.72 }} />
    </Fab>
  );
}
