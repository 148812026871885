import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import { routes } from "./Routes";
import { theme } from "./Theme";
import { RecoilRoot } from "recoil";
import InitialRecoilRequests from "./InitialRecoilRequests";

const App = () => {
  return (
    <RecoilRoot>
      <Router>
        <AuthProvider>
          <InitialRecoilRequests />
          <ThemeProvider theme={theme}>
            <main className="root">{routes}</main>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </RecoilRoot>
  );
};

export default App;
