import React from "react";
import { Typography, Grid, Divider } from "@mui/material";
import { useRecoilValue } from "recoil";
import { groupedCategoriesAtom, groupedItemsAtom, groupedSectionsAtom } from "../../recoil/atoms";

export default function FrozenColumn({ rowHeight }) {
  const borderColor = "#aaa";
  const bgColor = "#f6f6f6";
  const boxShadow = "0 1px 0 0 #ddd";
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);

  return (
    <>
      {groupedCategories && groupedCategories.expenses.map((category) => (
        <React.Fragment key={category.id}>
          <Grid container columns={1} sx={{ height: rowHeight.category }}>
            <Grid item sm={1}>
              <Typography
                sx={{ pl: 2, ml: 0, boxShadow: boxShadow }}
                variant="summaryCategory"
                component="div"
              >
                {category.label}
              </Typography>
            </Grid>
          </Grid>
          {groupedSections &&
            groupedSections[category.id].map((section) => (
              <React.Fragment key={section.id}>
                <Grid container columns={1} sx={{ height: rowHeight.section }}>
                  <Grid item sm={1}>
                    <Typography
                      sx={{
                        ml: 7,
                        pl: 1,
                        borderLeft: 2,
                        borderColor: borderColor,
                        boxShadow: boxShadow,
                      }}
                      variant="summarySection"
                      component="div"
                    >
                      {section.label}
                    </Typography>
                  </Grid>
                </Grid>
                {groupedItems &&
                  groupedItems[section.id].map((item) => (
                    <React.Fragment key={item.id}>
                      <Grid container columns={1} sx={{ height: rowHeight.item }}>
                        <Grid item sm={1}>
                          <Typography
                            sx={{
                              ml: 7,
                              pl: 5,
                              borderLeft: 2,
                              borderColor: "#aaa",
                              borderBottom: "1px solid #eee",
                            }}
                            variant="summaryItem"
                            component="div"
                          >
                            {item.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Grid container columns={1} sx={{ height: rowHeight.section }}>
                  <Grid item sm={1}>
                    <Typography
                      variant="summarySection"
                      component="div"
                      sx={{
                        backgroundColor: bgColor,
                        pl: 5,
                        ml: 7,
                        borderLeft: 2,
                        borderColor: borderColor,
                      }}
                    >
                      Subtotal
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ ml: 7 }} />
              </React.Fragment>
            ))}
          <Grid container columns={1} sx={{ height: rowHeight.category }}>
            <Grid item sm={1}>
              <Typography
                sx={{ pl: 2, backgroundColor: "#e6e6e6" }}
                variant="summaryCategory"
                component="div"
              >
                Subtotal
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ borderWidth: 2, borderColor: "#000" }} />
        </React.Fragment>
      ))}
      <Grid
        container
        columns={1}
        sx={{ backgroundColor: "#333", color: "#fff", borderLeft: 2, borderColor: "#333" }}
      >
        <Grid item sm={1}>
          <Typography sx={{ pl: 0 }} align="right" variant="h2" component="div">
            Total Expenses
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ borderWidth: 5, borderColor: "#000" }} />
    </>
  );
}
