import React from "react";
import { Typography, Grid } from "@mui/material";

export default function SectionRow({ field, padding, variant, dimensions, margin }) {
  const borderColor = "#aaa";
  return (
    <>
      <Grid container columns={dimensions.total}>
        <Grid item sm={dimensions.first}>
          <Typography
            sx={{ ml: margin, pl: padding, borderLeft: 2, borderColor: borderColor }}
            variant={variant}
            component="div"
          >
            {field.label}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second}></Grid>
        <Grid item sm={dimensions.third}></Grid>
        <Grid item sm={dimensions.fourth}></Grid>
        <Grid item sm={dimensions.fifth}></Grid>
      </Grid>
    </>
  );
}
