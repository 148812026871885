import React from "react";

import { Box } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import RevenueSection from "./RevenueSection";
import ExpensesSection from "./ExpensesSection";
import NetSection from "./NetSection";

export default function SummaryContainer() {
  const { currentUserData } = useAuth();

  return (
    <Box sx={{ mt: 3 }}>
      {currentUserData.permissions === "all" && <NetSection />}
      {<RevenueSection />}
      {<ExpensesSection />}
    </Box>
  );
}
