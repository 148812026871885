import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { groupedCategoriesAtom } from "../../recoil/atoms";
import { Paper, Typography, Button, Box, Divider } from "@mui/material";
import CategoryDisplay from "./CategoryDisplay";
import EditDialog from "./EditDialog";
import CreateDialog from "./CreateDialog";

export default function SettingsContainer() {
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const [createObj, setCreateObj] = useState();
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedField, setSelectedField] = useState();
  

  const handleOpen = (e) => {
    setCreateObj({ parentId: e.target.name, collection: "categories" });
    setCreateOpen(true);
  };
  return (
    <>
      <Typography variant="h1" sx={{ mt: 3 }}>
        Settings
      </Typography>
      <Paper sx={{ padding: 2, mt: 5 }}>
        <Box sx={{ mt: 2, ml: 4, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2">Revenue</Typography>
          <Button variant="contained" onClick={handleOpen} name="revenue">
            Create New Category
          </Button>
        </Box>
        <Divider sx={{ mt: 1, ml: 4 }} />
        {groupedCategories &&
          groupedCategories.revenue.map((category) => (
            <CategoryDisplay
              category={category}
              key={category.id}
              setCreateObj={setCreateObj}
              setCreateOpen={setCreateOpen}
              setSelectedField={setSelectedField}
              selectedField={selectedField}
              setEditOpen={setEditOpen}
            />
          ))}

        <Box sx={{ mt: 2, ml: 4, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2">Expenses</Typography>
          <Button variant="contained" onClick={handleOpen} name="expenses">
            Create New Category
          </Button>
        </Box>
        <Divider sx={{ mt: 1, ml: 4 }} />
        {groupedCategories &&
          groupedCategories.expenses.map((category) => (
            <CategoryDisplay
              category={category}
              key={category.id}
              setCreateObj={setCreateObj}
              setCreateOpen={setCreateOpen}
              setSelectedField={setSelectedField}
              selectedField={selectedField}
              setEditOpen={setEditOpen}
            />
          ))}
      </Paper>
      <CreateDialog createObj={createObj} open={createOpen} setOpen={setCreateOpen} />
      <EditDialog
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        selectedField={selectedField}
        setSelectedField={selectedField}
      />
    </>
  );
}
