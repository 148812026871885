import React from "react";
import { useRecoilValue } from "recoil";
import { groupedSectionsAtom } from "../../recoil/atoms";
import { Typography, Box, Button, Divider } from "@mui/material";
import SectionDisplay from "./SectionDisplay";
import FieldEditIcon from "./FieldEditIcon";
import FieldDeleteIcon from "./FieldDeleteIcon";

export default function CategoryDisplay({
  category,
  setCreateObj,
  setCreateOpen,
  setSelectedField,
  selectedField,
  setEditOpen
}) {
  const groupedSections = useRecoilValue(groupedSectionsAtom);


  const handleOpen = () => {
    setCreateObj({ parentId: category.id, collection: "sections" });
    setCreateOpen(true);
  };

  const handleEnter = (value) => {
    setSelectedField({ ...value, collection: "categories" });
  };

  const handleExit = () => {
    setSelectedField(null);
  };
  return (
    <>
      <Box sx={{ mt: 2, ml: 8, display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", alignItems: "baseline" }}
          onMouseEnter={() => handleEnter(category)}
          onMouseLeave={handleExit}
        >
          <Typography variant="h3">{category.label}</Typography>
          {selectedField && selectedField.id === category.id && (
            <>
              <FieldEditIcon setEditOpen={setEditOpen} /> <FieldDeleteIcon selectedField={selectedField} />
            </>
          )}
        </Box>
        <Button variant="outlined" onClick={handleOpen}>
          Create New Section
        </Button>
      </Box>
      <Divider sx={{ mt: 1, ml: 8 }} />
      {groupedSections &&
        groupedSections[category.id]?.map((section) => (
          <SectionDisplay
            section={section}
            key={section.id}
            setCreateObj={setCreateObj}
            setCreateOpen={setCreateOpen}
            setSelectedField={setSelectedField}
            selectedField={selectedField}
            setEditOpen={setEditOpen}
          />
        ))}
    </>
  );
}
