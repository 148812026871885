import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: "#26C6F5",
        contrastText: "#fff",
      },
      secondary: {
        main: "#B930E6",
      },
      error: {
        main: "#EB3D3D",
      },
    },
    typography: {
      fontFamily: "Urbanist, Arial",
      h1: {
        fontSize: 48,
        fontWeight: 800,
      },

      h2: {
        fontSize: 40,
      },
      h3: {
        fontSize: 30,
      },
      h4: {
        fontSize: 26,
      },
      h5: {
        fontSize: 20,
        color: "#555",
      },
      h6: {
        fontSize: 14,
        fontWeight: 800,
      },
      body1: {
        fontSize: 18,
        fontWeight: 300,
      },
      subtitle1: {
        fontSize: 12,
        color: "#fff",
      },
      cardMenu: {
        fontSize: 14,
        color: "#333",
      },
      cardTitle: {
        fontSize: 24
      },
      summaryCategory: {
        fontSize: 24,
        fontWeight: 800
      },
      summarySection: {
        fontSize: 18,
        fontWeight: 800
      },
      summaryItem: {
        fontSize: 18
      }
    },
  })
);
