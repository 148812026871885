import React, { useState, useEffect } from "react";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import {
  staffResetAtom,
  categoriesResetAtom,
  sectionsResetAtom,
  itemsResetAtom,
} from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from "@mui/material";
import UserList from "./UserList";

export default function EditDialog({ editOpen, selectedField, setEditOpen }) {
  const blankForm = { label: "", order: "", id: "" };
  const [form, setForm] = useState(blankForm);
  const setStaffReset = useSetRecoilState(staffResetAtom);
  const [title, setTitle] = useState("");
  const [collection, setCollection] = useState();
  const { sendRequest: updateDoc } = useUpdateDoc();
  const setCategoriesReset = useSetRecoilState(categoriesResetAtom);
  const setSectionsReset = useSetRecoilState(sectionsResetAtom);
  const setItemsReset = useSetRecoilState(itemsResetAtom);

  useEffect(() => {
    if (!selectedField) {
      return;
    }
    setForm({
      label: selectedField.label,
      order: selectedField.order,
      id: selectedField.id,
      amount: selectedField.collection === "items" ? selectedField.amount : null,
    });
    setCollection(selectedField.collection);
    setTitle(
      selectedField.collection === "categories"
        ? "Category"
        : selectedField.collection === "sections"
        ? "Section"
        : "Item"
    );
  }, [selectedField]);

  const handleChange = (e) => {
    setForm((prevValue) => {
      return { ...prevValue, [e.target.name]: e.target.value };
    });
  };

  const handleClose = () => {
    setEditOpen(false);
    setStaffReset((pV) => !pV);
  };

  const handleSave = async () => {
    const data = { ...form, order: +form.order };
    await updateDoc({ col: collection, data: data, id: form.id });
    setForm(blankForm);
    setEditOpen(false);
    setStaffReset((pV) => !pV);
    setCategoriesReset((pV) => !pV);
    setSectionsReset((pV) => !pV);
    setItemsReset((pV) => !pV);
  };

  return (
    <Dialog open={editOpen} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 44 }}>Edit {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Category Label:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="label"
          type="text"
          fullWidth
          value={form.label}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Order</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="order"
          type="number"
          fullWidth
          value={form.order}
          variant="outlined"
          onChange={handleChange}
        />
        {form.amount && (
          <>
            <DialogContentText>Budgeted Amount</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="amount"
              type="number"
              fullWidth
              value={form.amount}
              variant="outlined"
              onChange={handleChange}
            />
          </>
        )}
        {true && <UserList fieldId={form.id} collection={collection} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
