import React, { useState, useEffect } from "react";
import { fiscalYearAtom, transactionsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import CodeTable from "./CodeTable";

export default function CodeContainer() {
  const transactions = useRecoilValue(transactionsAtom);
  const fiscalYear = useRecoilValue(fiscalYearAtom);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!transactions || !fiscalYear) {
      return;
    }

    const checkTransaction = ({ transaction, fiscalYear }) => {
      if (fiscalYear === "21-22") {
        if (
          (transaction.year === 2021 && transaction.month > 5) ||
          (transaction.year === 2022 && transaction.month < 6)
        ) {
          return true;
        }
      } else if (fiscalYear === "22-23") {
        if (
          (transaction.year === 2022 && transaction.month > 5) ||
          (transaction.year === 2023 && transaction.month < 6)
        ) {
          return true;
        }
      } else {
        return false;
      }
    };

    const currency = Intl.NumberFormat("en-US");
    const tempRows = [];
    transactions.forEach((row) => {
      const thisFiscalYear = checkTransaction({ transaction: row, fiscalYear: fiscalYear });
      if (!thisFiscalYear) {
        return;
      }
      tempRows.push({
        label: row.label,
        amount: `$ ${currency.format(row.amount)}`,
        id: row.id,
        month: row.month + 1,
        date: row.date,
        name: row.name,
        itemId: row.itemId ? row.itemId : "",
        staffId: row.staffId ? row.staffId : "",
        amountRaw: row.amount,
      });
    });
    setRows(tempRows);
  }, [transactions, fiscalYear]);
  return (
    <>
      <CodeTable rows={rows} />
    </>
  );
}
