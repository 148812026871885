import React, { useEffect, useState } from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  projectionsObjAtom,
  groupedItemsAtom,
  groupedSectionsAtom,
  groupedTransactionsAtom,
} from "../../recoil/atoms";
import { months } from "../../libraries/objects";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { getSectionTotalProjections, formatShortDate } from "../../libraries/util";

export default function CategoryTotalRow({ field, padding, variant, dimensions, rowHeight }) {
  const { convertCurrency } = useConvertCurrency();
  const projectionsObj = useRecoilValue(projectionsObjAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [spent, setSpent] = useState(0);
  const [budgeted, setBudgeted] = useState(0);
  const bgColor = "#e6e6e6";

  useEffect(() => {
    const getCategorySpent = () => {
      let total = 0;
      groupedSections[field.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          if (!groupedTransactions[item.id]) return;
          groupedTransactions[item.id].forEach((transaction) => {
            total += +transaction.amount;
          });
        });
      });
      setSpent(total);
    };
    getCategorySpent();
  }, [field, groupedSections, groupedItems, groupedTransactions]);

  useEffect(() => {
    const getCategoryBudgeted = () => {
      let total = 0;
      groupedSections[field.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          total += +item.amount;
        });
      });
      setBudgeted(total);
    };

    getCategoryBudgeted();
  }, [field, groupedItems, groupedSections]);

  const getCategorySpentByMonth = (month) => {
    let total = 0;
    groupedSections[field.id].forEach((section) => {
      groupedItems[section.id].forEach((item) => {
        if (!groupedTransactions[item.id]) return;
        groupedTransactions[item.id].forEach((transaction) => {
          if (formatShortDate(transaction.date) === month) {
            total += +transaction.amount;
          }
        });
      });
    });
    return total;
  };

  const getProjectionsByMonth = ({ month }) => {
    let total = 0;
    groupedSections[field.id].forEach((section) => {
      total += +getSectionTotalProjections({
        projectionsObj,
        month,
        groupedItems,
        sectionId: section.id,
      });
    });
    return total;
  };
  return (
    <>
      <Grid container columns={dimensions.total} sx={{ height: rowHeight.category }}>
        <Grid item sm={dimensions.budgeted} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(Math.abs(budgeted))}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.spent} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(Math.abs(spent))}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.left} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(Math.abs(budgeted) - Math.abs(spent))}
          </Typography>
        </Grid>
        {months &&
          months.map((month) => (
            <Grid item sm={dimensions.month} key={month} sx={{ backgroundColor: bgColor }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    sx={{ pl: padding, backgroundColor: bgColor }}
                    component="div"
                    align="right"
                    variant={variant}
                  >
                    {convertCurrency(getProjectionsByMonth({ month }))}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ pl: padding, backgroundColor: bgColor }}
                    component="div"
                    align="right"
                    variant={variant}
                  >
                    {convertCurrency(Math.abs(getCategorySpentByMonth(month)))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Divider sx={{ borderWidth: 2, borderColor: "#000" }} />
    </>
  );
}
