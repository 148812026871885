import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LoginContainer from "../components/Login/LoginContainer";

export default function Login() {
  const { currentUserData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserData) {
      navigate("/summary");
    }else{
      navigate("/")
    }
  }, [currentUserData, navigate]);

  return <LoginContainer />;
}
