import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { staffResetAtom } from "../../recoil/atoms";
import { Box, InputLabel, MenuItem, FormControl } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";

import Select from "@mui/material/Select";

export default function SelectPermissions({ staff }) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [permission, setPermission] = useState(staff.permissions || "");
  const setStaffReset = useSetRecoilState(staffResetAtom);

  const handleChange = (event) => {
    setPermission(event.target.value);
    updateDoc({ col: "staff", id: staff.id, data: { permissions: event.target.value } });
    setStaffReset((pV) => !pV);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="permissions-label">Permissions</InputLabel>
        <Select
          labelId="permissions-label"
          id="permissions-select"
          value={permission}
          label="Permissions"
          onChange={handleChange}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"sections"}>Section Specific</MenuItem>
          <MenuItem value={"items"}>Item Specific</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
