import React from "react";
import { Typography, Grid } from "@mui/material";
export default function CategoryRow({ dimensions, rowHeight }) {
  const boxShadow = "0 1px 0 0 #ddd";

  return (
    <>
      <Grid container columns={dimensions.total} sx={{ height: rowHeight.category }}>
        <Typography
          sx={{ boxShadow: boxShadow, width: "100%" }}
          variant="summaryCategory"
          component="div"
        >
          {" "}
        </Typography>
      </Grid>
    </>
  );
}
