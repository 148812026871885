import React from "react";

import ExpenseTable from "./ExpenseTable";

export default function ExpenseTrackingContainer() {
  


  return (
    <>

        <ExpenseTable />
        {/* <Box sx={{ textAlign: "center", mt: 6 }}>
          <div>Loading</div>
          <CircularProgress color="primary" size={80} thickness={5} />
        </Box> */}
    </>
  );
}
