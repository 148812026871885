import { itemsAtom, transactionsAtom, sectionsAtom, categoriesAtom } from "./atoms";
import { groupBy } from "lodash";

export const groupTransactionsGetter = ({ get }) => {
  const transactions = get(transactionsAtom);
  const groupedByItemId = groupBy(transactions, "itemId");
  return groupedByItemId;
};

export const groupItemsGetter = ({ get }) => {
  const items = get(itemsAtom);
  if (items) {
    const groupedBySectionId = groupBy(items, "parentId");
    return groupedBySectionId;
  } else {
    return null;
  }
};

export const groupSectionsGetter = ({ get }) => {
  const sections = get(sectionsAtom);
  if (sections) {
    const groupedByCategoryId = groupBy(sections, "parentId");
    return groupedByCategoryId;
  } else {
    return null;
  }
};

export const groupCategoriesGetter = ({ get }) => {
  const categories = get(categoriesAtom);
  if (categories) {
    const groupedByTypeId = groupBy(categories, "parentId");
    return groupedByTypeId;
  } else {
    return null;
  }
};
