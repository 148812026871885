import { atom, selector } from "recoil";
import {
  groupTransactionsGetter,
  groupItemsGetter,
  groupSectionsGetter,
  groupCategoriesGetter,
} from "./selectors";

export const projectionsObjAtom = atom({
  key: "projectionsObj",
  default: null,
});

export const projectionsResetAtom = atom({
  key: "projectionsReset",
  default: false,
});

export const transactionsAtom = atom({
  key: "transactions",
  default: null,
});

export const transactionsObjAtom = atom({
  key: "transactionsObj",
  default: null,
});

export const groupedTransactionsAtom = selector({
  key: "groupedTransactions",
  get: groupTransactionsGetter,
});

export const transactionsResetAtom = atom({
  key: "transactionsReset",
  default: false,
});

export const itemsAtom = atom({
  key: "items",
  default: null,
});

export const itemsObjAtom = atom({
  key: "itemsObj",
  default: null,
});

export const groupedItemsAtom = selector({
  key: "groupedItems",
  get: groupItemsGetter,
});

export const itemsResetAtom = atom({
  key: "itemsReset",
  default: false,
});

export const sectionsAtom = atom({
  key: "sections",
  default: null,
});

export const groupedSectionsAtom = selector({
  key: "groupedSections",
  get: groupSectionsGetter,
});

export const sectionsResetAtom = atom({
  key: "sectionsReset",
  default: false,
});

export const categoriesAtom = atom({
  key: "categories",
  default: null,
});

export const groupedCategoriesAtom = selector({
  key: "groupedCategories",
  get: groupCategoriesGetter,
});

export const categoriesResetAtom = atom({
  key: "categoriesReset",
  default: false,
});

export const fiscalYearAtom = atom({
  key: "fiscalYear",
  default: "22-23",
});

export const staffAtom = atom({
  key: "staff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const automationsAtom = atom({
  key: "automations",
  default: null,
});

export const automationsResetAtom = atom({
  key: "automationsReset",
  default: false
})