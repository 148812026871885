import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Typography, IconButton, Badge, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../contexts/AuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FiscalYearSelect from "./FiscalYearSelect";
import { SITE_PERMISSIONS_OBJ } from "../../libraries/objects";

export default function NavBar() {
  const { currentUser, currentUserData } = useAuth();
  const { logout } = useAuth();
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [collapse, setCollapse] = useState(false);

  const handleResize = () => {
    if (width > 1000) {
      setCollapse(false);
    }

    if (width <= 1000) {
      setCollapse(true);
    }
  };

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ displayPrint: "none" }} position="static">
        <Toolbar>
          {collapse && currentUser && (
            <div>
              <IconButton
                size="large"
                color="inherit"
                onClick={handleClick}
                aria-controls="simple-menu"
                aria-haspopup="true"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {currentUserData?.sitePermissions.includes(SITE_PERMISSIONS_OBJ.edit_settings) && (
                  <MenuItem onClick={handleClose}>
                    <Link to="/settings" className="mobileLink">
                      Settings
                    </Link>
                  </MenuItem>
                )}
                {currentUserData && (
                  <MenuItem onClick={handleClose}>
                    <Link to="/summary" className="mobileLink">
                      Summary
                    </Link>
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TGP Finance
          </Typography>
          {!collapse &&
            currentUserData?.sitePermissions.includes(SITE_PERMISSIONS_OBJ.edit_settings) && (
              <Link to="/settings" className="link">
                Settings
              </Link>
            )}
          {!collapse && currentUserData?.sitePermissions.includes(SITE_PERMISSIONS_OBJ.edit_staff) && (
            <Link to="/staff" className="link">
              Staff
            </Link>
          )}
          {!collapse && currentUserData && (
            <Link to="/summary" className="link">
              Summary
            </Link>
          )}
          {!collapse &&
            currentUserData?.sitePermissions.includes(SITE_PERMISSIONS_OBJ.upload_transactions) && (
              <Link to="/upload" className="link">
                Upload
              </Link>
            )}

          {!collapse && currentUserData && <FiscalYearSelect />}
          {currentUser && (
            <IconButton aria-label="logout" color="inherit" onClick={handleLogout}>
              <Badge color="secondary">
                <ExitToAppIcon />
              </Badge>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
