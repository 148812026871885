import React, { useState, useEffect } from "react";
import { Paper, Typography, Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { groupedCategoriesAtom, groupedSectionsAtom, groupedItemsAtom } from "../../recoil/atoms";
import ExpensesHeader from "./ExpensesHeader";
import ItemRow from "./ItemRow";
import SectionRow from "./SectionRow";
import CategoryRow from "./CategoryRow";
import CategoryTotalRow from "./CategoryTotalRow";
import TotalRow from "./TotalRow";
import SectionTotalRow from "./SectionTotalRow";

export default function ExpensesSection({ expenses }) {
  const [toggle, setToggle] = useState({});
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const dimensions = { total: 20, first: 5, second: 4, third: 4, fourth: 4, fifth: 3 };

  useEffect(() => {
    if (!groupedCategories || !groupedSections || !groupedItems) return;
    const tempObj = {};
    groupedCategories.expenses.forEach((category) => {
      tempObj[category.id] = true;
    });
    setToggle(tempObj);
  }, [groupedCategories, groupedSections, groupedItems]);

  return (
    <>
      <Typography sx={{ mt: 5 }} variant="h2">
        Expenses
      </Typography>
      <Paper sx={{ padding: 2 }}>
        <ExpensesHeader dimensions={dimensions} />
        <Box sx={{ height: "80vh", overflow: "scroll" }}>
          {groupedCategories &&
            groupedCategories.expenses.map((category) => (
              <Box key={category.id} sx={{ borderLeft: 2 }}>
                <CategoryRow
                  field={category}
                  padding={2}
                  margin={0}
                  variant={"summaryCategory"}
                  setToggle={setToggle}
                  toggle={toggle}
                  dimensions={dimensions}
                />
                {toggle && toggle[category.id] === true && (
                  <>
                    {groupedSections &&
                      groupedSections[category.id].map((section) => (
                        <Box key={section.id}>
                          {groupedItems && groupedItems[section.id].length > 0 && (
                            <>
                              {" "}
                              <SectionRow
                                field={section}
                                padding={1}
                                margin={7}
                                variant={"summarySection"}
                                show={category.show}
                                dimensions={dimensions}
                              />
                              {groupedItems &&
                                groupedItems[section.id].map((item) => (
                                  <ItemRow
                                    key={item.id}
                                    field={item}
                                    padding={5}
                                    margin={7}
                                    variant={"summaryItem"}
                                    show={category.show}
                                    dimensions={dimensions}
                                  />
                                ))}
                              <SectionTotalRow
                                field={section}
                                padding={5}
                                margin={7}
                                variant={"summarySection"}
                                dimensions={dimensions}
                              />
                            </>
                          )}
                        </Box>
                      ))}
                  </>
                )}
                <CategoryTotalRow
                  field={category}
                  padding={2}
                  variant={"summaryCategory"}
                  dimensions={dimensions}
                />
              </Box>
            ))}
          <TotalRow dimensions={dimensions} label={"Expenses"} />
        </Box>
      </Paper>
    </>
  );
}
