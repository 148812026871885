import React from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { months } from "../../libraries/objects";

export default function ExpensesHeader({ dimensions, rowHeight }) {
  const bgColor = ["#fff", "#FDFCE5"];
  return (
    <>
      <Grid container spacing={1} columns={dimensions.total} sx={{ mb: 2, height: rowHeight.header }}>
        <Grid item sm={dimensions.budgeted}>
          <Typography align="right" variant="h4">
            Budgeted
          </Typography>
        </Grid>
        <Grid item sm={dimensions.spent}>
          <Typography align="right" variant="h4">
            Spent
          </Typography>
        </Grid>
        <Grid item sm={dimensions.left}>
          <Typography align="right" variant="h4">
            Left
          </Typography>
        </Grid>
        {months &&
          months.map((month, index) => (
            <Grid
              item
              sm={dimensions.month}
              key={month}
              sx={{ backgroundColor: bgColor[index % 2] }}
            >
              <Typography align="center" variant="h4">
                {month}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Typography align="right" variant="h5">
                    Budgeted
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant="h5">
                    Actual
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Divider />
    </>
  );
}
