import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Paper,
  Button,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Papa from "papaparse";
import { itemsAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import useAddDoc from "../../hooks/useAddDoc";
import useGetDocs from "../../hooks/useGetDocs";

const UploadContainer = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const { sendRequest: addDoc } = useAddDoc();
  const { sendRequest: getDocs } = useGetDocs();
  const [toggle, setToggle] = useState(true);
  const [last, setLast] = useState(false);
  const [duplicates, setDuplicates] = useState(0);
  const [transCount, setTransCount] = useState(0);
  const items = useRecoilValue(itemsAtom);

  const [allTransactions, setAllTransactions] = useState();

  useEffect(() => {
    const getTransactions = async () => {
      const tempResults = await getDocs({ col: "transactions", config: {} });
      setAllTransactions(tempResults[0]);
    };
    getTransactions();
  }, [getDocs]);

  useEffect(() => {
    if (!last || loading) {
      return;
    }
    setSuccessMessage(
      ` ${transCount - duplicates} out of ${transCount} Transactions Successfully Uploaded`
    );
    setLoading(false);
  }, [last, loading, duplicates, transCount]);

  const handleToggle = () => {
    setToggle((pV) => {
      return !pV;
    });
  };

  const searchTransactions = (data) => {
    const results = allTransactions.filter((transaction) => {
      return (
        transaction.amount === data.amount &&
        transaction.date === data.date &&
        transaction.itemId === data.itemId
      );
    });
    return results;
  };

  const createFinanceNumber = (amount) => {
    return Number(amount.replace(/[,]+/g, "").trim());
  };

  const addBankTransaction = async ({ value, index, last }) => {
    if (value.Amount) {
      setTransCount((pV) => {
        return pV + 1;
      });
      const month = new Date(value[`Date`]).getMonth();
      const year = new Date(value[`Date`]).getFullYear();
      const data = {
        amount: createFinanceNumber(value.Amount) * -1,
        label: value.Description,
        date: value[`Date`],
        month: month,
        year: year,
        name: "bank",
        itemId: value.itemId,
      };
      const search = searchTransactions(data);
      console.log(data);
      console.log("Search Results: ", search);
      if (search.length > 0) {
        setDuplicates((pV) => {
          return pV + 1;
        });
      } else {
        setLoading(true);
        await addDoc({ col: "transactions", data: data });
        setLoading(false);
      }
      if (transCount === duplicates) {
        setLoading(false);
      }
    }
  };

  const addCCTransaction = async ({ value, index, last }) => {
    if (value.amount && value[`Date`]) {
      setTransCount((pV) => {
        return pV + 1;
      });
      const month = new Date(value[`Date`]).getMonth();
      const year = new Date(value[`Date`]).getFullYear();
      const data = {
        amount: createFinanceNumber(value.amount),
        label: value.Description,
        date: value[`Date`],
        month: month,
        year: year,
        name: value[`Account Name`],
        itemId: value.itemId,
      };
      const search = searchTransactions(data);
      console.log(data);
      console.log("Search Results: ", search);
      if (search.length > 0) {
        setDuplicates((pV) => {
          return pV++;
        });
      } else {
        setLoading(true);

        if (data.itemId === "VrT4XIWLuXiNUl3O23rD") {
          console.log(data);
        }
        await addDoc({ col: "transactions", data: data });
        setLoading(false);
      }
      if (transCount === duplicates) {
        setLoading(false);
      }
    }
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      setLoading(true);
      Papa.parse(selectedFile, {
        complete: function (results) {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            if (toggle) {
              addBankTransaction({ value, index, last });
            } else {
              addCCTransaction({ value, index, last });
            }
          });
        },
        header: true,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "1em",
        }}
      >
        <Avatar sx={{ width: 120, height: 120, mb: 3 }} src="../TGPicon.png" />
      </Box>
      <Paper>
        <Box sx={{ textAlign: "center", pt: 3 }}>
          <Typography variant="h1">Upload Transactions</Typography>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              m: 2,
              mb: 2,
            }}
          >
            <CircularProgress size={60} sx={{ mb: 2 }} />
          </Box>
        ) : (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            {successMessage && <Typography variant="h4">{successMessage}</Typography>}
            <input
              id="uploadFile"
              type="file"
              name="file"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <label htmlFor="uploadFile">
              {" "}
              <Button variant="contained" sx={{ margin: 3 }} component="span">
                Choose File
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 3 }}
              onClick={() => {
                handleSubmission();
              }}
            >
              Submit
            </Button>
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
          </Box>
        )}
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "1em",
          }}
        >
          <FormControlLabel
            checked={toggle}
            onChange={handleToggle}
            control={<Switch />}
            label={toggle ? "Bank" : "Credit Card"}
          />
        </Box>
      </Paper>
      <table>
        <tbody>
          {items &&
            items.map((item) => (
              <tr key={item.id}>
                <td>{item.label}</td>
                <td>{item.id}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default UploadContainer;
