import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import FieldEditIcon from "./FieldEditIcon";
import FieldDeleteIcon from "./FieldDeleteIcon";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function ItemDisplay({ item, selectedField, setSelectedField, setEditOpen }) {
  const handleEnter = (value) => {
    setSelectedField({ ...value, collection: "items" });
  };

  const handleExit = () => {
    setSelectedField(null);
  };
  return (
    <>
      <Box sx={{ mt: 2, ml: 16, display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", alignItems: "baseline" }}
          onMouseEnter={() => handleEnter(item)}
          onMouseLeave={handleExit}
        >
          <Typography variant="h5">{item.label}</Typography>
          {selectedField && selectedField.id === item.id && (
            <>
              <FieldEditIcon setEditOpen={setEditOpen} /> <FieldDeleteIcon selectedField={selectedField} />
            </>
          )}
        </Box>
        <Typography variant="h5"> {`${formatter.format(item.amount)}`}</Typography>
      </Box>
      <Divider sx={{ mt: 1, ml: 16 }} />
    </>
  );
}
