import React, { useState } from "react";
import { Divider, Typography, Box, Grid } from "@mui/material";
import ExpensesHeader from "./ExpensesHeader";
import ItemRow from "./ItemRow";
import SectionRow from "./SectionRow";
import CategoryRow from "./CategoryRow";
import CategoryTotalRow from "./CategoryTotalRow";
import TotalRow from "./TotalRow";
import SectionTotalRow from "./SectionTotalRow";
import FrozenColumn from "./FrozenColumn";
import { useRecoilValue } from "recoil";
import { groupedCategoriesAtom, groupedItemsAtom, groupedSectionsAtom } from "../../recoil/atoms";

const rowHeight = { header: 60, category: 36, section: 27, item: 27 };

export default function ExpenseTable() {
  const dimensions = { total: 42, budgeted: 2, spent: 2, left: 2, month: 3 };
  const [toggle, setToggle] = useState(true);
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);

  return (
    <>
      <Typography sx={{ mt: 5 }} variant="h2">
        Expenses
      </Typography>
      <Box sx={{ overflow: "scroll", height: "80vh" }}>
        <table
          style={{
            width: 5000,
            backgroundColor: "#fff",
            borderSpacing: 0,
            borderCollapse: "collapse",
            position: "relative",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  left: 0,
                  position: "sticky",
                  zIndex: 99999,
                  width: 400,
                  backgroundColor: "#fff",
                  verticalAlign: "top",
                  padding: 0,
                  border: 0,
                  top: 0,
                }}
              >
                <Grid container spacing={1} columns={1} sx={{ mb: 2, height: rowHeight.header }}>
                  <Grid item sm={1}>
                    <Typography sx={{ pl: 4 }} variant="h4">
                      Item
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </th>
              <th
                style={{
                  width: 4600,
                  verticalAlign: "top",
                  zIndex: 9999,
                  padding: 0,
                  border: 0,
                  position: "sticky",
                  backgroundColor: "#fff",
                  top: 0,
                }}
              >
                <ExpensesHeader dimensions={dimensions} rowHeight={rowHeight} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  left: 0,
                  position: "sticky",
                  zIndex: 9999,
                  width: 400,
                  backgroundColor: "#fff",
                  verticalAlign: "top",
                  padding: 0,
                  border: 0,
                }}
              >
                <FrozenColumn rowHeight={rowHeight} />
              </td>
              <td
                style={{
                  width: 4600,
                  verticalAlign: "top",
                  padding: 0,
                  border: 0,
                }}
              >
                {groupedCategories &&
                  groupedCategories.expenses.map((category) => (
                    <Box key={category.id}>
                      <CategoryRow
                        field={category}
                        padding={0}
                        margin={0}
                        variant={"summaryCategory"}
                        setToggle={setToggle}
                        toggle={toggle}
                        dimensions={dimensions}
                        rowHeight={rowHeight}
                      />
                      <>
                        {groupedSections &&
                          groupedSections[category.id].map((section) => (
                            <Box key={section.id}>
                              <>
                                {" "}
                                <SectionRow
                                  field={section}
                                  padding={0}
                                  margin={0}
                                  variant={"summarySection"}
                                  show={category.show}
                                  dimensions={dimensions}
                                  rowHeight={rowHeight}
                                />
                                {groupedItems &&
                                  groupedItems[section.id].map((item) => (
                                    <ItemRow
                                      key={item.id}
                                      field={item}
                                      padding={0}
                                      margin={0}
                                      variant={"summaryItem"}
                                      show={category.show}
                                      dimensions={dimensions}
                                      rowHeight={rowHeight}
                                    />
                                  ))}
                                <SectionTotalRow
                                  field={section}
                                  padding={0}
                                  margin={0}
                                  variant={"summarySection"}
                                  dimensions={dimensions}
                                  rowHeight={rowHeight}
                                />
                              </>
                            </Box>
                          ))}
                      </>
                      <CategoryTotalRow
                        field={category}
                        padding={2}
                        variant={"summaryCategory"}
                        dimensions={dimensions}
                        rowHeight={rowHeight}
                      />
                    </Box>
                  ))}
                <TotalRow dimensions={dimensions} label={"Expenses"} rowHeight={rowHeight} />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </>
  );
}
