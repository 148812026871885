import { useEffect } from "react";
import {
  projectionsObjAtom,
  projectionsResetAtom,
  transactionsAtom,
  transactionsObjAtom,
  transactionsResetAtom,
  itemsAtom,
  itemsObjAtom,
  itemsResetAtom,
  sectionsAtom,
  sectionsResetAtom,
  categoriesAtom,
  categoriesResetAtom,
  fiscalYearAtom,
  staffAtom,
  staffResetAtom,
  automationsAtom,
  automationsResetAtom,
} from "./recoil/atoms";
import useGetDocs from "./hooks/useGetDocs";

import { useSetRecoilState, useRecoilValue } from "recoil";

export default function InitialRecoilRequests() {
  const setProjectionsObj = useSetRecoilState(projectionsObjAtom);
  const setTransactions = useSetRecoilState(transactionsAtom);
  const setItems = useSetRecoilState(itemsAtom);
  const setSections = useSetRecoilState(sectionsAtom);
  const setCategories = useSetRecoilState(categoriesAtom);
  const setStaff = useSetRecoilState(staffAtom);
  const setItemsObj = useSetRecoilState(itemsObjAtom);
  const setAutomations = useSetRecoilState(automationsAtom);
  const setTransactionsObj = useSetRecoilState(transactionsObjAtom);
  const transactionsReset = useRecoilValue(transactionsResetAtom);
  const projectionsReset = useRecoilValue(projectionsResetAtom);
  const itemsReset = useRecoilValue(itemsResetAtom);
  const sectionsReset = useRecoilValue(sectionsResetAtom);
  const categoriesReset = useRecoilValue(categoriesResetAtom);
  const fiscalYear = useRecoilValue(fiscalYearAtom);
  const staffReset = useRecoilValue(staffResetAtom);
  const automationsReset = useRecoilValue(automationsResetAtom);
  const { sendRequest: getDocs } = useGetDocs();

  useEffect(() => {
    const getTransactions = async () => {
      const [arr, obj] = await getDocs({ col: "transactions" });
      setTransactions(arr);
      setTransactionsObj(obj);
    };
    getTransactions();
  }, [getDocs, setTransactions, transactionsReset, setTransactionsObj]);

  useEffect(() => {
    const getProjections = async () => {
      const [arr, obj] = await getDocs({ col: "projections" });
      setProjectionsObj(obj);
    };
    getProjections();
  }, [setProjectionsObj, getDocs, projectionsReset]);

  useEffect(() => {
    const getItems = async () => {
      const [arr, obj] = await getDocs({ col: "items", config: { orderBy: ["order"] } });
      setItems(arr.filter((item) => item.fiscalYear === fiscalYear));
      setItemsObj(obj);
    };
    getItems();
  }, [setItems, getDocs, itemsReset, fiscalYear, setItemsObj]);

  useEffect(() => {
    const getSections = async () => {
      const [arr] = await getDocs({ col: "sections", config: { orderBy: ["order"] } });
      setSections(arr);
    };
    getSections();
  }, [setSections, getDocs, sectionsReset]);

  useEffect(() => {
    const getCategories = async () => {
      const [arr] = await getDocs({ col: "categories", config: { orderBy: ["order"] } });

      setCategories(arr);
    };
    getCategories();
  }, [setCategories, getDocs, categoriesReset]);

  useEffect(() => {
    const getStaff = async () => {
      const [arr] = await getDocs({ col: "staff" });

      setStaff(arr);
    };
    getStaff();
  }, [setStaff, getDocs, staffReset]);

  useEffect(() => {
    const getAutomations = async () => {
      const [arr] = await getDocs({ col: "automations" });

      setAutomations(arr);
    };
    getAutomations();
  }, [setAutomations, getDocs, automationsReset]);

  return null;
}
