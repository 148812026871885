import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

export default function SuperAdminRoute({ children, permission }) {
  const { currentUserData } = useAuth();

  if (currentUserData && currentUserData.sitePermissions?.includes(permission)) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}
