import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { staffResetAtom } from "../../recoil/atoms";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
} from "@mui/material";
import useAddDoc from "../../hooks/useAddDoc";

const blankForm = {
  firstName: "",
  lastName: "",
  email: "",
  sections: [],
  items: [],
};

const CreateDialog = ({ open, setOpen }) => {
  const [form, setForm] = useState({ ...blankForm });
  const { sendRequest: addDoc } = useAddDoc();
  const setStaffReset = useSetRecoilState(staffResetAtom);

  const handleChange = (e) => {
    setForm((prevValue) => {
      return { ...prevValue, [e.target.name]: e.target.value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    await addDoc({ col: "staff", data: form });
    setForm(blankForm);
    setOpen(false);
    setStaffReset((pV) => !pV);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 44 }}>Add New Staff Member</DialogTitle>
      <DialogContent>
        <DialogContentText>First Name:</DialogContentText>
        <TextField
          autoFocus
          name="firstName"
          type="text"
          required
          fullWidth
          value={form.firstName}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Last Name:</DialogContentText>
        <TextField
          autoFocus
          name="lastName"
          type="text"
          required
          fullWidth
          value={form.lastName}
          variant="outlined"
          onChange={handleChange}
        />
        <DialogContentText>Email:</DialogContentText>
        <TextField
          autoFocus
          name="email"
          type="email"
          required
          fullWidth
          value={form.email}
          variant="outlined"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Submit</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CreateDialog);
