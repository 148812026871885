import React from "react";

import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@mui/material";

export default function DeleteConfirmation({ open, setOpen, handleDelete }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: 44 }}>Confirm</DialogTitle>
      <DialogContent>
        Are you sure you want to delete? If you do, all items inside sections and all coded
        transactions will be lost.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>Yes, I'm sure</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
