import React from "react";
import { CssBaseline, Container } from "@mui/material";
import NavBar from "../components/Navigation/NavBar";
import ExpenseTrackingContainer from "../components/ExpenseTracking/ExpenseTrackingContainer";

export default function ExpenseTracking() {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="xl" sx={{ minHeight: "100vh" }}>
        <ExpenseTrackingContainer />
      </Container>
    </>
  );
}
