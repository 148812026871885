import { useState } from "react";
import { Typography, Divider, Grid } from "@mui/material";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import TransactionDetails from "./TransactionDetails";

export default function TransactionRow({ transaction }) {
  const [open, setOpen] = useState(false);
  const { convertCurrency } = useConvertCurrency();
  const { width } = useWindowDimensions();

  const handleClick = () => {
    setOpen(true);
  };

  const truncateDescription = (text) => {
    const characters = width / 23;
    const slicedText = text.slice(0, characters);
    const ending = text.length > slicedText.length ? ". . ." : "";
    return `${slicedText} ${ending}`;
  };
  return (
    <>
      <Grid container spacing={0} sx={{ padding: 1 }} onClick={handleClick}>
        <Grid item sm={2}>
          <Typography>{transaction.date}</Typography>
          <Divider />
        </Grid>
        <Grid item sm={9}>
          <Typography>{truncateDescription(transaction.label)}</Typography>
          <Divider />
        </Grid>

        <Grid item sm={1}>
          <Typography align="right">{convertCurrency(transaction.amount)}</Typography>
          <Divider />
        </Grid>
      </Grid>
      <TransactionDetails open={open} setOpen={setOpen} transaction={transaction} />
    </>
  );
}
