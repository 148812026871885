import React, { useState, useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { staffResetAtom } from "../../recoil/atoms";
import { FormControlLabel, Switch } from "@mui/material";
import { SITE_PERMISSIONS } from "../../libraries/objects";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function SetSitePermissions({ staff }) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [sitePermissions, setSitePermissions] = useState();
  const setStaffReset = useSetRecoilState(staffResetAtom);

  useEffect(() => {
    if (!staff || sitePermissions) return;
    setSitePermissions(staff.sitePermissions || []);
  }, [staff, sitePermissions]);

  const handleChange = useCallback(
    async (e) => {
      if (!sitePermissions) return;
      let currentPermissions = [...sitePermissions];
      if (e.target.checked && !currentPermissions.includes(e.target.name)) {
        currentPermissions.push(e.target.name);
      } else if (!e.target.checked && currentPermissions.includes(e.target.name)) {
        currentPermissions = currentPermissions.filter(
          (permission) => permission !== e.target.name
        );
      }
      setSitePermissions(currentPermissions);
      await updateDoc({
        col: "staff",
        id: staff.id,
        data: { sitePermissions: currentPermissions },
      });
      setStaffReset((pV)=> !pV);
    },
    [sitePermissions, staff, updateDoc, setStaffReset]
  );

  return (
    <>
      {SITE_PERMISSIONS.map((permission) => (
        <FormControlLabel
          key={permission}
          control={
            <Switch
              onChange={handleChange}
              name={permission}
              checked={sitePermissions?.includes(permission) || false}
            />
          }
          label={permission}
        />
      ))}
    </>
  );
}
