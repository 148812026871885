import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  groupedCategoriesAtom,
  itemsAtom,
  transactionsAtom,
  sectionsAtom,
} from "../../recoil/atoms";
import { Paper, Typography, Box, Grid, Divider, CircularProgress } from "@mui/material";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { filterTransactions } from "../../libraries/util";

export default function NetSection() {
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const transactions = useRecoilValue(transactionsAtom);
  const items = useRecoilValue(itemsAtom);
  const sections = useRecoilValue(sectionsAtom);
  const { convertCurrency } = useConvertCurrency();
  const [revenue, setRevenue] = useState({ budgeted: 0, spent: 0 });
  const [expenses, setExpenses] = useState({ budgeted: 0, spent: 0 });
  const [revenueItems, setRevenueItems] = useState();
  const [expenseItems, setExpensesItems] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!revenueItems || !expenseItems) return;
    setLoading(false);
  }, [revenueItems, expenseItems]);

  useEffect(() => {
    if (!revenueItems) return;
    const revenueTransactions = filterTransactions({
      items: revenueItems,
      transactions: transactions,
    });
    const total = revenueTransactions.reduce((pV, transaction) => pV + transaction.amount, 0);
    setRevenue((pV) => ({ ...pV, spent: Math.abs(total) }));
  }, [revenueItems, transactions]);

  useEffect(() => {
    if (!expenseItems) return;
    const expenseTransactions = filterTransactions({
      items: expenseItems,
      transactions: transactions,
    });
    const total = expenseTransactions.reduce((pV, transaction) => pV + transaction.amount, 0);
    setExpenses((pV) => ({ ...pV, spent: Math.abs(total) }));
  }, [expenseItems, transactions]);

  useEffect(() => {
    if (!groupedCategories || !sections || !items) return;
    const revenueCategories = groupedCategories.revenue.map((category) => category.id);
    const revenueSections = [];
    sections.forEach((section) => {
      if (revenueCategories.includes(section.parentId)) {
        revenueSections.push(section.id);
      }
    });
    const tempRevenueItems = items.filter((item) => revenueSections.includes(item.parentId));
    setRevenueItems(tempRevenueItems);
  }, [groupedCategories, sections, items]);

  useEffect(() => {
    if (!groupedCategories || !sections || !items) return;
    const expenseCategories = groupedCategories.expenses.map((category) => category.id);
    const expenseSections = [];
    sections.forEach((section) => {
      if (expenseCategories.includes(section.parentId)) {
        expenseSections.push(section.id);
      }
    });
    const tempExpenseItems = items.filter((item) => expenseSections.includes(item.parentId));
    setExpensesItems(tempExpenseItems);
  }, [groupedCategories, sections, items]);

  useEffect(() => {
    if (!revenueItems) return;
    const budgeted = revenueItems.reduce((pV, item) => pV + +item.amount, 0);
    setRevenue((pV) => ({ ...pV, budgeted: Math.abs(budgeted) }));
  }, [revenueItems]);

  useEffect(() => {
    if (!expenseItems) return;
    const budgeted = expenseItems.reduce((pV, item) => pV + +item.amount, 0);
    setExpenses((pV) => ({ ...pV, budgeted: Math.abs(budgeted) }));
  }, [expenseItems]);

  const dimensions = { total: 20, first: 5, second: 4, third: 4, fourth: 4, fifth: 3 };

  return (
    <>
      {loading && (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Typography variant="h2">Net</Typography>
          <Paper sx={{ padding: 2, mt: 2, mb: 2 }}>
            <Box>
              <Grid container spacing={1} columns={dimensions.total} sx={{ mb: 2 }}>
                <Grid item sm={dimensions.first}>
                  <Typography sx={{ pl: 4 }} variant="h4">
                    Item
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.second}>
                  <Typography align="right" variant="h4">
                    Budgeted
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.third}>
                  <Typography align="right" variant="h4">
                    Spent
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fourth}>
                  <Typography align="right" variant="h4">
                    Left
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fifth}>
                  <Typography align="right" variant="h4"></Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={1} columns={dimensions.total} sx={{ mb: 1 }}>
                <Grid item sm={dimensions.first}>
                  <Typography sx={{ pl: 4 }} variant="h5">
                    Total Revenue
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.second}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(revenue.budgeted)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.third}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(revenue.spent)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fourth}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(revenue.budgeted - revenue.spent)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fifth}>
                  <Typography align="right" variant="h5"></Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} columns={dimensions.total} sx={{ mb: 1 }}>
                <Grid item sm={dimensions.first}>
                  <Typography sx={{ pl: 4 }} variant="h5">
                    Total Expenses
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.second}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(expenses.budgeted)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.third}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(expenses.spent)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fourth}>
                  <Typography align="right" variant="h5">
                    {convertCurrency(expenses.budgeted - expenses.spent)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fifth}>
                  <Typography align="right" variant="h5"></Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} columns={dimensions.total}>
                <Grid item sm={dimensions.first}>
                  <Typography sx={{ pl: 4, fontWeight: 800 }} variant="h5">
                    Total Net
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.second}>
                  <Typography sx={{ fontWeight: 800 }} align="right" variant="h5">
                    {convertCurrency(revenue.budgeted - expenses.budgeted)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.third}>
                  <Typography sx={{ fontWeight: 800 }} align="right" variant="h5">
                    {convertCurrency(revenue.spent - expenses.spent)}
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fourth}>
                  <Typography sx={{ fontWeight: 800 }} align="right" variant="h5">
                    N/A
                  </Typography>
                </Grid>
                <Grid item sm={dimensions.fifth}>
                  <Typography align="right" variant="h5"></Typography>
                </Grid>
              </Grid>
              <Divider />
            </Box>
          </Paper>
        </>
      )}
    </>
  );
}
