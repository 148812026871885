import { useState, useCallback } from "react";
import { collection, getDocs, where, orderBy, limit, query } from "firebase/firestore";
import { db } from "../firebase";

const useGetDocs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async ({ col, config }) => {
    setIsLoading(true);
    setError(null);
    try {
      const conditions = [where("active", "==", true)];
      if (config?.where) {
        conditions.push(where(config.where[0], config.where[1], config.where[2]));
      }
      if (config?.orderBy) {
        conditions.push(orderBy(config.orderBy[0], config[1] ? config[1] : "asc"));
      }
      if (config?.limit) {
        conditions.push(limit(config.limit));
      }
      const docRef = collection(db, col);
      const q = query(docRef, ...conditions);
      const snapshot = await getDocs(q);

      const tempArray = [];
      const tempObj = {};

      snapshot.forEach((s) => {
        const data = { ...s.data() };
        tempArray.push({ id: s.id, ...data });
        tempObj[s.id] = { ...data };
      });
      return [tempArray, tempObj];
    } catch (err) {
      setError(err.message || "Something went wrong!");
      console.log(err)
    }
    setIsLoading(false);
  }, []);
  return { isLoading, error, sendRequest };
};

export default useGetDocs;
