import React, { useState, useEffect } from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { groupedTransactionsAtom, groupedItemsAtom } from "../../recoil/atoms";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import PercentageDisplay from "./PercentageDisplay";

export default function SectionTotalRow({ field, padding, variant, dimensions, margin }) {
  const { convertCurrency } = useConvertCurrency();
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [budgeted, setBudgeted] = useState(0);
  const [spent, setSpent] = useState();

  useEffect(() => {
    const getSectionBudgeted = () => {
      let total = 0;
      groupedItems[field.id].forEach((item) => {
        total += +item.amount;
      });
      setBudgeted(Math.abs(total));
    };
    getSectionBudgeted();
  }, [groupedItems, field]);

  useEffect(() => {
    const getCategorySpent = () => {
      let total = 0;
      groupedItems[field.id].forEach((item) => {
        if (!groupedTransactions[item.id]) return;
        groupedTransactions[item.id].forEach((transaction) => {
          total += +transaction.amount;
        });
      });
      setSpent(Math.abs(total));
    };
    getCategorySpent();
  }, [field, groupedItems, groupedTransactions]);

  const bgColor = "#f6f6f6";
  const borderColor = "#aaa";
  return (
    <>
      <Grid container columns={dimensions.total}>
        <Grid item sm={dimensions.first}>
          <Typography
            variant={variant}
            component="div"
            sx={{
              backgroundColor: bgColor,
              pl: padding,
              ml: margin,
              borderLeft: 2,
              borderColor: borderColor,
            }}
          >
            Subtotal
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(budgeted)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.third} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fourth} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(budgeted - spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fifth} sx={{ backgroundColor: bgColor }}>
          <PercentageDisplay left={budgeted - spent} amount={budgeted} spent={spent} />
        </Grid>
      </Grid>
      <Divider sx={{ ml: margin, borderWidth: 1, borderColor: "#999" }} />
    </>
  );
}
