import React, { useState, useEffect } from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import {
  groupedItemsAtom,
  groupedSectionsAtom,
  groupedTransactionsAtom,
  groupedCategoriesAtom,
} from "../../recoil/atoms";
import useConvertCurrency from "../../hooks/useConvertCurrency";

export default function TotalRow({ dimensions, label }) {
  const { convertCurrency } = useConvertCurrency();
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [spent, setSpent] = useState(0);
  const [budgeted, setBudgeted] = useState(0);
  const [categories, setCategories] = useState();

  useEffect(() => {
    if (!groupedCategories) return;

    setCategories(label === "Revenue" ? groupedCategories.revenue : groupedCategories.expenses);
  }, [groupedCategories, label]);

  useEffect(() => {
    if (!categories || !groupedSections || !groupedItems || !groupedTransactions) return;
    let total = 0;
    categories.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          if (!groupedTransactions[item.id]) return;
          groupedTransactions[item.id].forEach((transaction) => {
            total += +transaction.amount;
          });
        });
      });
    });
    setSpent(total);
  }, [categories, groupedSections, groupedItems, groupedTransactions, label]);

  useEffect(() => {
    if (!categories || !groupedSections || !groupedItems) return;
    let total = 0;
    categories.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          total += +item.amount;
        });
      });
    });
    setBudgeted(total);
  }, [categories, groupedItems, groupedSections]);

  return (
    <>
      <Grid
        container
        columns={dimensions.total}
        sx={{ backgroundColor: "#333", color: "#fff", borderLeft: 2, borderColor: "#333" }}
      >
        <Grid item sm={dimensions.first}>
          <Typography sx={{ pl: 0 }} variant="h2" component="div">
            Total {label}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second}>
          <Typography sx={{ pl: 0 }} align="right" variant="h2" component="div">
            {convertCurrency(budgeted)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.third}>
          <Typography sx={{ pl: 0 }} align="right" variant="h2" component="div">
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fourth}>
          <Typography sx={{ pl: 0 }} align="right" variant="h2" component="div">
            {convertCurrency(budgeted - spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fifth}></Grid>
      </Grid>
      <Divider sx={{ borderWidth: 5, borderColor: "#000" }} />
    </>
  );
}
