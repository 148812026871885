import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { itemsObjAtom, transactionsObjAtom } from "../../recoil/atoms";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SelectCode from "./SelectCode";

export default function CodeTable({ rows }) {
  const window = useWindowDimensions();
  const transactionsObj = useRecoilValue(transactionsObjAtom);
  const itemsObj = useRecoilValue(itemsObjAtom);
  const [columns, setColumns] = useState();

  const [checked, setChecked] = useState(true);

  console.log(itemsObj)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (!itemsObj || !transactionsObj) return;
    setColumns([
      {
        field: "name",
        headerName: "Account",
        minWidth: 100,
        flex: 1,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        headerAlign: "center",
      },
      {
        field: "date",
        headerName: "Date",
        minWidth: 100,
        flex: 1,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        headerAlign: "center",
      },
      {
        field: "month",
        headerName: "Month",
        minWidth: 50,
        flex: 1,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        headerAlign: "center",
      },
      {
        field: "label",
        headerName: "Desc",
        minWidth: 100,
        flex: 4,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        headerAlign: "center",
      },
      {
        field: "amountRaw",
        headerName: "Amount",
        minWidth: 100,
        flex: 1,
        headerClassName: "tableHeader amountColumn",
        align: "right",
        headerAlign: "right",
        cellClassName: "hoverPointer",
        valueGetter: getCurrency,
        sortComparator: amountComparator,
      },
      {
        field: "code",
        headerName: "Code",
        minWidth: 100,
        flex: 6,
        headerClassName: "tableHeader",
        headerAlign: "center",
        cellClassName: "hoverPointer",
        valueFormatter: (value) => {
          const itemId = transactionsObj[value.id].itemId;
          return itemsObj[itemId]?.label || "None";
        },
        renderCell: (cellValues) => {
          return checked ? (
            <SelectCode transaction={cellValues} />
          ) : (
            <div>{cellValues.formattedValue}</div>
          );
        },
      },
    ]);
  }, [itemsObj, transactionsObj, checked]);

  const getCurrency = (params) => {
    const currency = Intl.NumberFormat("en-US");
    return `$ ${currency.format(params.value)}`;
  };

  const amountComparator = (v1, v2) => {
    const n1 = Number(v1.replace(/[^0-9.-]+/g, ""));
    const n2 = Number(v2.replace(/[^0-9.-]+/g, ""));
    return Number(n1 - n2);
  };

  return (
    <Box sx={{ mt: 5 }}>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Display For Export"
      />
      {rows && columns && (
        <div style={{ height: window.height - 300, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rows}
                id="transactions-table"
                columns={columns}
                components={{
                  Toolbar: GridToolbar,
                }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}
