import React from "react";
import {CssBaseline, Container} from "@mui/material";
import NavBar from "../components/Navigation/NavBar";
import StaffContainer from "../components/Staff/StaffContainer";

export default function Staff() {
  return (
    <>
      <CssBaseline />
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
      <StaffContainer />
      </Container>
    </>
  );
}
