import React from "react";
import { Box } from "@mui/material";
import ReactHtmlParser from "react-html-parser";

export default function PercentageDisplay({ amount, spent, left }) {
  const numPercentSpent = Math.round(Math.abs((spent / amount) * 100));
  const numPercentLeft = Math.round(Math.abs((left / amount) * 100));
  const borderRadius = 0;
  return (
    <Box sx={{pl: 1}}>
      <Box sx={{ display: "flex", alignItems: "stretch" }}>
        <Box
          sx={{
            backgroundColor: "#B930E6",
            flexBasis: `${numPercentSpent}%`,
            minHeight: "100%",
            height: "100%",
            textAlign: "center",
            borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
            color: "white",
          }}
        >
          {numPercentSpent > 20 ? `${numPercentSpent}%` :(numPercentSpent === 0) ? "" : ReactHtmlParser("&nbsp;")}
        </Box>
        <Box
          sx={{
            backgroundColor: "#aaa",
            flexBasis: `${numPercentLeft}%`,
            minHeight: "100%",
            height: "100%",
            textAlign: "center",
            borderRadius: `0 ${borderRadius} ${borderRadius} 0`,
            color: "white"
          }}
        >
          {numPercentLeft > 20 ? `${numPercentLeft}%` : ReactHtmlParser(":&nbsp;")}
        </Box>
      </Box>
    </Box>
  );
}
