import { useCallback } from "react";

const useConvertCurrency = () => {
  const convertCurrency = useCallback((amount) => {
    try {
      const currency = Intl.NumberFormat("en-US");
      return `$${currency.format(Math.round(amount))}`;
    } catch (err) {
      console.log(err);
    }
  }, []);
  return { convertCurrency };
};

export default useConvertCurrency;
