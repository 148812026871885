import React from "react";
import { Typography, Grid } from "@mui/material";

export default function SectionRow({ field, padding, variant, dimensions, rowHeight }) {
  const boxShadow = "0 1px 0 0 #ddd";

  return (
    <>
      <Grid container columns={dimensions.total} sx={{ height: rowHeight.section }}>
        <Typography sx={{ boxShadow: boxShadow, width: "100%" }}></Typography>
      </Grid>
    </>
  );
}
