import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { months } from "../../libraries/objects";
import { useRecoilValue } from "recoil";
import { groupedTransactionsAtom } from "../../recoil/atoms";
import TextEdit from "./TextEdit";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { filterByMonth, getItemTotalSpent } from "../../libraries/util";

export default function ItemRow({ field, variant, dimensions, rowHeight }) {
  const bgColor = ["#fff", "#FDFCE5"];
  const { convertCurrency } = useConvertCurrency();
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [spent, setSpent] = useState(0);

  useEffect(() => {
    if (!groupedTransactions) return;
    const total = getItemTotalSpent({ groupedTransactions, fieldId: field.id });
    setSpent(total);
  }, [groupedTransactions, field]);

  const getItemTransactionsByMonth = ({ month }) => {
    if (!groupedTransactions[field.id]) return 0;
    const transactionsToSum = filterByMonth({ groupedTransactions, fieldId: field.id, month });
    const total = transactionsToSum.reduce((sV, transaction) => sV + transaction.amount, 0);
    return convertCurrency(Math.abs(total));
  };

  return (
    <>
      <Grid container columns={dimensions.total} sx={{ height: rowHeight.item }}>
        <Grid item sm={dimensions.budgeted}>
          <Typography
            align="right"
            variant={variant}
            component="div"
            sx={{ borderBottom: "1px solid", borderColor: "#eee" }}
          >
            {convertCurrency(Math.abs(field.amount))}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.spent}>
          <Typography
            align="right"
            variant={variant}
            component="div"
            sx={{ borderBottom: "1px solid", borderColor: "#eee" }}
          >
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.left}>
          <Typography
            align="right"
            variant={variant}
            component="div"
            sx={{ borderBottom: "1px solid", borderColor: "#eee" }}
          >
            {convertCurrency(Math.abs(field.amount) - spent)}
          </Typography>
        </Grid>
        {months &&
          months.map((month, index) => (
            <Grid
              item
              sm={dimensions.month}
              key={month}
              sx={{
                backgroundColor: bgColor[index % 2],
                border: "1px solid #eee",
                borderLeft: "0 solid",
                borderRight: "0 solid",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <TextEdit field={field} month={month} variant={variant} />
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" variant={variant} component="div">
                    {getItemTransactionsByMonth({ month })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
