import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TransactionDetailsForm from "./TransactionDetailsForm";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function TransactionDetails({ open, setOpen, transaction }) {
  const [form, setForm] = useState(transaction);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    console.log(transaction)
    updateDoc({ col: "transactions", id: transaction.id, data: { ...form } });
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{ padding: 5 }}>
        <DialogTitle variant="h3">Edit Transaction</DialogTitle>
        <DialogContent>
          <TransactionDetailsForm form={form} setForm={setForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
