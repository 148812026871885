import React, {useState, useEffect} from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { months } from "../../libraries/objects";
import { useRecoilValue } from "recoil";
import { projectionsObjAtom, groupedTransactionsAtom, groupedItemsAtom } from "../../recoil/atoms";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { getSectionTotalProjections, formatShortDate } from "../../libraries/util";

export default function SectionTotalRow({ field, padding, variant, dimensions, rowHeight }) {
  const bgColor = "#f6f6f6";
  const monthBGColor = ["#fff", "#FDFCE5"];
  const projectionsObj = useRecoilValue(projectionsObjAtom);
  const { convertCurrency } = useConvertCurrency();
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [budgeted, setBudgeted] = useState(0);
  const [spent, setSpent] = useState();

  useEffect(() => {
    const getSectionBudgeted = () => {
      let total = 0;
      groupedItems[field.id].forEach((item) => {
        total += +item.amount;
      });
      setBudgeted(Math.abs(total))
    };
    getSectionBudgeted();
  }, [groupedItems, field]);

  useEffect(() => {
    const getCategorySpent = () => {
      let total = 0;
        groupedItems[field.id].forEach((item) => {
          if (!groupedTransactions[item.id]) return;
          groupedTransactions[item.id].forEach((transaction) => {
            total += +transaction.amount;
          });
        });
      setSpent(Math.abs(total));
    };
    getCategorySpent();
  }, [field, groupedItems, groupedTransactions]);





  const getSectionBudgetedByMonth = (month) => {
    let total = 0;
    groupedItems[field.id].forEach((item) => {
      if (!groupedTransactions[item.id]) return;
      groupedTransactions[item.id].forEach((transaction) => {
        if (formatShortDate(transaction.date) === month) {
          total += +transaction.amount;
        }
      });
    });
    return total;
  };

  return (
    <>
      <Grid container columns={dimensions.total} sx={{ height: rowHeight.section }}>
        <Grid item sm={dimensions.budgeted}>
          <Typography
            sx={{ pl: padding, backgroundColor: bgColor }}
            align="right"
            variant={variant}
            component="div"
          >
            {convertCurrency(budgeted)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.spent}>
          <Typography
            sx={{ pl: padding, backgroundColor: bgColor }}
            align="right"
            variant={variant}
            component="div"
          >
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.left}>
          <Typography
            sx={{ pl: padding, backgroundColor: bgColor }}
            component="div"
            align="right"
            variant={variant}
          >
            {convertCurrency(budgeted-spent)}
          </Typography>
        </Grid>
        {months &&
          months.map((month, index) => (
            <Grid
              item
              sm={dimensions.month}
              key={month}
              sx={{
                backgroundColor: monthBGColor[index % 2],
                border: "1px solid #eee",
                borderLeft: "0 solid",
                borderRight: "0 solid",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    sx={{ pl: padding, backgroundColor: bgColor }}
                    component="div"
                    align="right"
                    variant={variant}
                  >
                    {convertCurrency(
                      getSectionTotalProjections({
                        projectionsObj,
                        month,
                        groupedItems,
                        sectionId: field.id,
                      })
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ pl: padding, backgroundColor: bgColor }}
                    component="div"
                    align="right"
                    variant={variant}
                  >
                    {convertCurrency(Math.abs(getSectionBudgetedByMonth(month)))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Divider />
    </>
  );
}
