import React from "react";
import { Typography, Grid, Fab } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export default function CategoryRow({
  field,
  padding,
  variant,
  setToggle,
  toggle,
  dimensions,
  margin,
}) {
  const toggleCategory = () => {
    setToggle((pV) => {
      const tempObj = { ...pV };
      tempObj[field.id] = !pV[field.id];
      return tempObj;
    });
  };
  return (
    <>
      <Grid container columns={dimensions.total}>
        <Grid item sm={dimensions.first}>
          <Typography sx={{ pl: padding, ml: margin }} variant={variant} component="div">
            {field.label}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second}></Grid>
        <Grid item sm={dimensions.third}></Grid>
        <Grid item sm={dimensions.fourth}></Grid>
        <Grid item sm={dimensions.fifth}>
          <Typography variant="p" align="right" component="div">
            <Fab
              color="primary"
              aria-label="add"
              sx={{
                width: 25,
                lineHeight: 25,
                height: 25,
                minHeight: 25,
                marginRight: 1,
                color: "#fff",
              }}
              size={"small"}
              onClick={toggleCategory}
            >
              {toggle[field.id] ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
            </Fab>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
