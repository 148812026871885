import React, {useState} from "react";
import { ListItem, ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import useUpdateDoc from "../../hooks/useUpdateDoc";

export default function PermissionItem({ staff, fieldId, collection }) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [displayStaff, setDisplayStaff] = useState(staff)

  const handleToggle = (e) => {
    const tempFields = [...staff[collection]];
    if (e.target.checked) {
      if (!staff[collection].includes(fieldId)) {
        tempFields.push(fieldId);
      }
    } else {
      const i = tempFields.indexOf(fieldId);
      if (i !== -1) {
        tempFields.splice(i, 1);
      }
    }
    setDisplayStaff((pV)=>{
      return {...pV, [collection]: tempFields}
    })
    updateDoc({ col: "staff", id: staff.id, data: { [collection]: tempFields } });
  };

  return (
    <ListItem disablePadding>
      <ListItemIcon>
        <Checkbox
          edge="start"
          disableRipple
          checked={displayStaff[collection].includes(fieldId) ? true : false}
          onClick={handleToggle}
          inputProps={{ "aria-labelledby": `${staff.firstName} ${staff.lastName}` }}
        />
      </ListItemIcon>
      <ListItemText id={staff.id} primary={`${staff.firstName} ${staff.lastName}`} />
    </ListItem>
  );
}
