import { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TransactionRow from "./TransactionRow";
import { sortDates } from "../../libraries/util";

export default function TransactionsListContainer({ open, setOpen, transactions, field }) {
  const [sortedTransactions, setSortedTransactions] = useState();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!transactions) return;
    setSortedTransactions(sortDates(transactions));
  }, [transactions]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" sx={{ padding: 5 }}>
        <DialogTitle variant="h3">{`Transaction Details for ${field.label}`}</DialogTitle>
        <DialogContent>
          {sortedTransactions &&
            sortedTransactions.map((transaction) => (
              <TransactionRow transaction={transaction} key={transaction.id} />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
