import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import CreateAutomation from "./CreateAutomation";
import ShowAutomations from "./ShowAutomations";
import {
  groupedCategoriesAtom,
  groupedSectionsAtom,
  groupedItemsAtom,
} from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function AutomateContainer() {
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    if (!groupedCategories || !groupedSections || !groupedCategories) return;
    const tempArray = [];
    groupedCategories.revenue.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          tempArray.push(item);
        });
      });
    });
    groupedCategories.expenses.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          tempArray.push(item);
        });
      });
    });
    setSortedItems(tempArray);
  }, [groupedCategories, groupedSections, groupedItems]);

  return (
    <>
      <Typography variant="h1" sx={{ mt: 3 }}>
        Automate
      </Typography>
      <CreateAutomation sortedItems={sortedItems} />
      <ShowAutomations sortedItems={sortedItems}  />
    </>
  );
}
