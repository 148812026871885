import React, { useState, useEffect } from "react";
import { Typography, Divider, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import { groupedItemsAtom, groupedSectionsAtom, groupedTransactionsAtom } from "../../recoil/atoms";
import useConvertCurrency from "../../hooks/useConvertCurrency";

export default function CategoryTotalRow({ field, padding, variant, dimensions }) {
  const { convertCurrency } = useConvertCurrency();
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const [spent, setSpent] = useState(0);
  const [budgeted, setBudgeted] = useState(0);

  useEffect(() => {
    const getCategorySpent = () => {
      let total = 0;
      groupedSections[field.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          if (!groupedTransactions[item.id]) return;
          groupedTransactions[item.id].forEach((transaction) => {
            total += +transaction.amount;
          });
        });
      });
      setSpent(total);
    };
    getCategorySpent();
  }, [field, groupedSections, groupedItems, groupedTransactions]);

  useEffect(() => {
    const getCategoryBudgeted = () => {
      let total = 0;
      groupedSections[field.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          total += +item.amount;
        });
      });
      setBudgeted(total);
    };

    getCategoryBudgeted();
  }, [field, groupedItems, groupedSections]);

  const bgColor = "#e6e6e6";
  return (
    <>
      <Grid container columns={dimensions.total}>
        <Grid item sm={dimensions.first}>
          <Typography
            sx={{ pl: padding, backgroundColor: bgColor }}
            variant={variant}
            component="div"
          >
            Subtotal
          </Typography>
        </Grid>
        <Grid item sm={dimensions.second} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(budgeted)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.third} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fourth} sx={{ backgroundColor: bgColor }}>
          <Typography sx={{ pl: padding }} align="right" variant={variant} component="div">
            {convertCurrency(budgeted - spent)}
          </Typography>
        </Grid>
        <Grid item sm={dimensions.fifth} sx={{ backgroundColor: bgColor }}></Grid>
      </Grid>
      <Divider sx={{ borderWidth: 2, borderColor: "#000" }} />
    </>
  );
}
