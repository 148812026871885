import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  CardHeader,
} from "@mui/material";
import SelectPermissions from "./SelectPermissions";
import SetSitePermissions from "./SetSitePermissions";

export default function StaffCard({ staff }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ width: 70, height: 70 }} />}
          title={
            <Typography variant="cardTitle">
              {staff.firstName} {staff.lastName}
            </Typography>
          }
        />
        <CardContent>
            <SelectPermissions staff={staff} />
            <SetSitePermissions staff={staff} />
        </CardContent>
      </Card>
    </Grid>
  );
}
