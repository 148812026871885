import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { groupedCategoriesAtom, groupedSectionsAtom, groupedItemsAtom } from "../../recoil/atoms";
import { Paper, Typography, Box } from "@mui/material";
import RevenueHeader from "./RevenueHeader";
import ItemRow from "./ItemRow";
import SectionRow from "./SectionRow";
import CategoryRow from "./CategoryRow";
import CategoryTotalRow from "./CategoryTotalRow";
import TotalRow from "./TotalRow";
import SectionTotalRow from "./SectionTotalRow";

export default function RevenueSection() {
  const [toggle, setToggle] = useState({});
  const [show, setShow] = useState(false);
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const dimensions = { total: 20, first: 5, second: 4, third: 4, fourth: 4, fifth: 3 };

  useEffect(() => {
    if (!groupedCategories || !groupedSections || !groupedItems) return;
    const tempObj = {};
    groupedCategories.revenue.forEach((category) => {
      tempObj[category.id] = true;
      groupedSections[category.id].forEach((section) => {
        if (groupedItems[section.id].length > 0) {
          setShow(true);
        }
      });
    });
    setToggle(tempObj);
  }, [groupedCategories, groupedSections, groupedItems]);

  return (
    <>
      {show && (
        <>
          <Typography variant="h2">Revenue</Typography>
          <Paper sx={{ padding: 2 }}>
            <RevenueHeader dimensions={dimensions} />
            <Box sx={{ height: "80vh", overflow: "scroll" }}>
              {groupedCategories &&
                groupedCategories.revenue.map((category) => (
                  <Box key={category.id} sx={{ borderLeft: 2 }}>
                    <CategoryRow
                      field={category}
                      padding={2}
                      margin={0}
                      variant={"summaryCategory"}
                      setToggle={setToggle}
                      toggle={toggle}
                      dimensions={dimensions}
                    />
                    {toggle && toggle[category.id] === true && (
                      <>
                        {groupedSections &&
                          groupedSections[category.id].map((section) => (
                            <Box key={section.id}>
                              {groupedItems && groupedItems[section.id].length > 0 && (
                                <Box>
                                  <SectionRow
                                    field={section}
                                    padding={1}
                                    margin={7}
                                    variant={"summarySection"}
                                    show={category.show}
                                    dimensions={dimensions}
                                  />
                                  {groupedItems &&
                                    groupedItems[section.id].map((item) => (
                                      <ItemRow
                                        key={item.id}
                                        field={item}
                                        padding={5}
                                        margin={7}
                                        variant={"summaryItem"}
                                        show={category.show}
                                        dimensions={dimensions}
                                      />
                                    ))}
                                  <SectionTotalRow
                                    field={section}
                                    padding={5}
                                    margin={7}
                                    variant={"summarySection"}
                                    dimensions={dimensions}
                                  />
                                </Box>
                              )}
                            </Box>
                          ))}
                      </>
                    )}
                    <CategoryTotalRow
                      field={category}
                      padding={2}
                      variant={"summaryCategory"}
                      dimensions={dimensions}
                    />
                  </Box>
                ))}
              <TotalRow dimensions={dimensions} label={"Revenue"} />
            </Box>
          </Paper>
        </>
      )}
    </>
  );
}
