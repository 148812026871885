import React, { useState } from "react";
import { automationsResetAtom } from "../../recoil/atoms";
import { useSetRecoilState } from "recoil";
import { TextField, Select, MenuItem, Paper, Typography, Grid, Button } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import useAddDoc from "../../hooks/useAddDoc";

export default function CreateAutomation({ sortedItems }) {
  const setAutomationsReset = useSetRecoilState(automationsResetAtom);
  const { sendRequest: addDoc } = useAddDoc();
  const [code, setCode] = useState("");
  const [text, setText] = useState("");

  const handleSelectChange = (e) => {
    setCode(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = () => {
    addDoc({ col: "automations", data: { text: text, itemId: code } });
    setAutomationsReset((pV) => !pV);
  };

  return (
    <Paper sx={{ padding: 2, mt: 3 }}>
      <Typography variant="h3">Create New Automation</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>If Description Contains: </Typography>
          <TextField value={text} fullWidth variant="outlined" onChange={handleTextChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Code It As: </Typography>
          <Select
            fullWidth
            sx={{ borderLeft: 0 }}
            id="code-select"
            value={code}
            onChange={handleSelectChange}
          >
            <MenuItem value={"none"}>None</MenuItem>
            {sortedItems &&
              sortedItems.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {ReactHtmlParser(item.label)}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button sx={{ mt: 2 }} onClick={handleSubmit} fullWidth variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
