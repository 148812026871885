import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { groupedTransactionsAtom } from "../../recoil/atoms";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { Grid, Typography } from "@mui/material";
import { getItemTotalSpent } from "../../libraries/util";
import TransactionDetailsContainer from "../TransactionsDialog/TransactionsListContainer";

export default function ItemRow({ field, padding, variant, dimensions, margin }) {
  const groupedTransactions = useRecoilValue(groupedTransactionsAtom);
  const { convertCurrency } = useConvertCurrency();
  const [spent, setSpent] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!groupedTransactions) return;
    const total = getItemTotalSpent({ groupedTransactions, fieldId: field.id });
    setSpent(total);
  }, [groupedTransactions, field]);

  const borderColor = "#aaa";
  const boxShadow = "0 1px 0 0 #ddd";
  return (
    <>
      <Grid container columns={dimensions.total}>
        <Grid item sm={dimensions.first} onClick={handleOpen}>
          <Typography
            sx={{
              ml: margin,
              pl: padding,
              borderLeft: 2,
              borderColor: borderColor,
              boxShadow: boxShadow,
            }}
            variant={variant}
            component="div"
          >
            {field.label}
          </Typography>
        </Grid>
        <Grid item sx={{ boxShadow: boxShadow }} sm={dimensions.second}>
          <Typography align="right" variant={variant} component="div">
            {convertCurrency(field.amount)}
          </Typography>
        </Grid>
        <Grid sx={{ boxShadow: boxShadow }} item sm={dimensions.third}>
          <Typography align="right" variant={variant} component="div">
            {convertCurrency(spent)}
          </Typography>
        </Grid>
        <Grid sx={{ boxShadow: boxShadow }} item sm={dimensions.fourth}>
          <Typography align="right" variant={variant} component="div">
            {convertCurrency(field.amount - spent)}
          </Typography>
        </Grid>
        <Grid sx={{ boxShadow: boxShadow }} item sm={dimensions.fifth}></Grid>
      </Grid>
      {open && (
        <TransactionDetailsContainer
          transactions={groupedTransactions[field.id]}
          open={open}
          setOpen={setOpen}
          field={field}
        />
      )}
    </>
  );
}
