import React from "react";
import { useRecoilValue } from "recoil";
import { staffAtom } from "../../recoil/atoms";
import { List } from "@mui/material";
import PermissionItem from "./PermissionItem";

export default function UserList({ fieldId, collection }) {
  const staff = useRecoilValue(staffAtom)

  return (
    <List>
      {(collection === "sections" || collection === "items") &&
        staff &&
        staff.map((s) => (
          <div key={s.id}>
            {s.permissions === collection && (
              <PermissionItem staff={s} fieldId={fieldId} collection={collection} />
            )}
          </div>
        ))}
    </List>
  );
}
