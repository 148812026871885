import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  groupedCategoriesAtom,
  groupedSectionsAtom,
  groupedItemsAtom,
  transactionsResetAtom,
} from "../../recoil/atoms";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { Autocomplete, TextField } from "@mui/material";

export default function SelectCode({ transaction }) {
  const { sendRequest: updateDoc } = useUpdateDoc();
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState({ label: "None", id: "None" });
  const [firstLoad, setFirstLoad] = useState(true);
  const [autocompleteOptions, setAutocompleteOptions] = useState();
  const groupedCategories = useRecoilValue(groupedCategoriesAtom);
  const groupedSections = useRecoilValue(groupedSectionsAtom);
  const groupedItems = useRecoilValue(groupedItemsAtom);
  const [sortedItems, setSortedItems] = useState([]);
  const setTransactionReset = useSetRecoilState(transactionsResetAtom);

  useEffect(() => {
    if (!groupedCategories || !groupedSections || !groupedCategories) return;
    const tempArray = [];
    groupedCategories.revenue.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          tempArray.push(item);
        });
      });
    });
    groupedCategories.expenses.forEach((category) => {
      groupedSections[category.id].forEach((section) => {
        groupedItems[section.id].forEach((item) => {
          tempArray.push({
            id: item.id,
            label: `${category.label} ${section.label} ${item.label}`,
          });
        });
      });
    });
    setSortedItems(tempArray);
  }, [groupedCategories, groupedSections, groupedItems]);

  useEffect(() => {
    if (!sortedItems) return;
    setAutocompleteOptions([{ label: "None", id: "None" }, ...sortedItems]);
  }, [sortedItems]);

  useEffect(() => {
    const [selected] = sortedItems.filter((item) => item.id === transaction.row.itemId);
    if (selected && firstLoad) {
      setSelectedValue(selected);
      setFirstLoad(false);
    }
  }, [sortedItems, transaction, firstLoad]);

  const handleSelect = async (event, value) => {
    if (value) {
      setSelectedValue(value);
      await updateDoc({
        col: "transactions",
        id: transaction.row.id,
        data: { itemId: value.id },
      });
    } else {
      setSelectedValue("");
      await updateDoc({
        col: "transactions",
        id: transaction.row.id,
        data: { itemId: null },
      });
    }
    setTransactionReset((pV) => !pV);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <>
      {autocompleteOptions && selectedValue && (
        <>
          <Autocomplete
            disablePortal
            value={selectedValue}
            options={autocompleteOptions}
            getOptionLabel={(option) => option.label || option}
            onChange={handleSelect}
            disableClearable
            inputValue={inputValue || "None"}
            onInputChange={handleInputChange}
            sx={{ fontSize: 2 }}
            fullWidth
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, style: { fontSize: 14 } }}
                />
              );
            }}
          />
        </>
      )}
    </>
  );
}
