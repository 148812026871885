import { DateTime } from "luxon";

export const getSectionTotalProjections = ({ month, groupedItems, projectionsObj, sectionId }) => {
  let total = 0;
  groupedItems[sectionId].forEach((item) => {
    if (projectionsObj[`${item.id}22-23`] && projectionsObj[`${item.id}22-23`][month]) {
      total += +projectionsObj[`${item.id}22-23`][month];
    }
  });
  return total;
};

export const formatShortDate = (transactionDate) => {
  let formattedDate = transactionDate;
  if (transactionDate.length === 8) {
    formattedDate = `${transactionDate.slice(0, 6)}20${transactionDate.slice(-2)}`;
  }

  return DateTime.fromFormat(formattedDate, "D").monthShort;
};

export const filterByMonth = ({ groupedTransactions, fieldId, month }) => {
  return groupedTransactions[fieldId].filter(
    (transaction) => formatShortDate(transaction.date) === month
  );
};

export const getItemTotalSpent = ({ groupedTransactions, fieldId }) => {
  if (!groupedTransactions[fieldId]) return 0;
  const total = groupedTransactions[fieldId].reduce(
    (sV, transaction) => sV + transaction.amount,
    0
  );
  return Math.abs(total);
};

export const filterTransactions = ({ items, transactions }) => {
  const tempArray = [];
  items.forEach((item) => {
    const transactionsPerItem = transactions.filter(
      (transaction) => transaction.itemId === item.id
    );
    tempArray.push(...transactionsPerItem);
  });
  return tempArray;
};

export const sortDates = (array) => {
  const newArray = [...array];
  newArray.sort((a, b) => {
    return DateTime.fromFormat(a.date, "D") > DateTime.fromFormat(b.date, "D") ? 1 : -1;
  });
  return newArray;
};
