import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fiscalYearAtom } from "../../recoil/atoms";
import { Box, MenuItem, Select } from "@mui/material";

export default function FiscalYearSelect() {
  const fiscalYear = useRecoilValue(fiscalYearAtom);
  const setFiscalYear = useSetRecoilState(fiscalYearAtom);

  const handleChange = (event) => {
    setFiscalYear(event.target.value);
  };

  return (
    <Box sx={{ color: "#fff", borderColor: "#fff" }}>
      <Select
        id="fiscalYear-select"
        value={fiscalYear}
        onChange={handleChange}
        sx={{ color: "white", fontWeight: 800, borderColor: "#fff", maxWidth: "30vw" }}
      >
        <MenuItem value={"21-22"}>SY 21 - 22</MenuItem>
        <MenuItem value={"22-23"}>SY 22 - 23</MenuItem>
      </Select>
    </Box>
  );
}
