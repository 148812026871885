import React, { useState, useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Typography } from "@mui/material";
import useConvertCurrency from "../../hooks/useConvertCurrency";
import { projectionsResetAtom, projectionsObjAtom } from "../../recoil/atoms";

export default function TextEdit({ field, month, variant }) {
  const inputRef = useRef(null);
  const [editToggle, setEditToggle] = useState(false);
  const setProjectionsReset = useSetRecoilState(projectionsResetAtom);
  const projectionsObj = useRecoilValue(projectionsObjAtom);
  const [value, setValue] = useState("");
  const { convertCurrency } = useConvertCurrency();

  useEffect(() => {
    if (!projectionsObj || !projectionsObj[`${field.id}22-23`]) return;
    setValue(projectionsObj[`${field.id}22-23`][month] || "");
  }, [projectionsObj, field, month]);

  useEffect(() => {
    if (editToggle) {
      inputRef.current.focus();
    }
  }, [editToggle]);

  const handleLeave = () => {
    setEditToggle(false);
    const projection = projectionsObj[`${field.id}22-23`];
    console.log(projection);
    // const data = { ...field, [month]: value };
    // setDoc({ col: "projections", id: field.id + fiscalYear, data: data });
    setProjectionsReset((pV) => !pV);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLeave();
    }
  };

  return (
    <>
      {editToggle ? (
        <input
          type="Number"
          ref={inputRef}
          onBlur={handleLeave}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography
          align="right"
          variant={variant}
          component="div"
          onClick={() => setEditToggle(true)}
        >
          {convertCurrency(value)}
        </Typography>
      )}
    </>
  );
}
