export const months = [
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
];

export const monthsObj = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sep: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export const SITE_PERMISSIONS = [
  "Code Transactions",
  "Upload Transactions",
  "Edit Staff",
  "Manage Automations",
  "See Expense Tracking",
  "Edit Settings",
];

export const SITE_PERMISSIONS_OBJ = {
  code_transactions: "Code Transactions",
  upload_transactions: "Upload Transactions",
  edit_staff: "Edit Staff",
  manage_automations: "Manage Automations",
  see_expense_tracking: "See Expense Tracking",
  edit_settings: "Edit Settings",
};
