import React from "react";
import { Button, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

export default function StaffLogin() {
  const { signInWithGoogle } = useAuth();

  return (
    <React.Fragment>
      <Typography variant="h2">Staff Sign In</Typography>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={signInWithGoogle}
        sx={{ marginTop: "2em" }}
      >
        Sign In With Google
      </Button>
    </React.Fragment>
  );
}
